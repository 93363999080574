<template>
  <section
    id="timer"
    class="h-full px-2">
    <div class="flex flex-row justify-between items-start mt-4 mb-6">
      <div class="w-1/3 relative">
        <h3 class="text-left text-light">{{ STRINGS.COMPANY_CODE }} #: {{ $store.state.auth.companyId }}</h3>
        <div
          class="pt-2 md:absolute left-0 -bottom-12"
          v-if="parameters && parameters.bypassPunchButtons && parameters.companyLogo">
          <img
            class="w-auto h-12 block object-contain object-center"
            :src="parameters.companyLogo" />
        </div>
      </div>
      <div class="w-1/3">
        <div
          class="flex flex-row justify-center items-center"
          v-if="isUserLogin">
          <h3 class="text-center text-light">{{ STRINGS.GREETING }} {{ $store.getters.fullName }}</h3>

          <button
            type="button"
            class="bg-grey rounded-full flex flex-col items-center justify-center relative ml-2"
            v-if="hasMessageNotification"
            @click.prevent="showYouHaveMessagesPopup">
            <inline-icon
              class="text-light w-6"
              name="message"></inline-icon>
            <span class="absolute top-0 right-0 bg-red rounded-full w-2 h-2 z-1"></span>
          </button>
        </div>
        <h3
          class="text-light text-center"
          v-else>
          {{ STRINGS.ENTER_NIP }}
        </h3>
      </div>
      <div class="w-1/3">
        <Time
          class="text-right"
          tag="h3" />
      </div>
    </div>

    <PunchStatus
      class="my-4 w-full"
      v-if="isUserLogin && parameters && !parameters.bypassPunchButtons" />

    <div class="grid gap-12 xl:gap-24 grid-cols-2 md:grid-cols-4">
      <div
        class="w-full col-span-2"
        :class="{
          'md:col-start-2': !isUserLogin || (parameters && parameters.bypassPunchButtons)
        }">
        <LoadingContainer :is-loading="isUserLoading">
          <div
            :class="{
              'pointer-events-none opacity-60': isUserLogin
            }">
            <form
              method="POST"
              @submit.prevent="onKeyboardSubmit"
              @keydown.enter.prevent="onKeyboardSubmit"
              accept-charset="UTF-8"
              class="flex flex-col justify-between h-full">
              <div
                class="flex flex-row justify-between items-center bg-green mt-8 h-12 px-3 rounded"
                v-if="isUserLogin && parameters && !parameters.bypassPunchButtons">
                <h2 class="text-light text-center w-full">{{ STRINGS.CONNECTED }}</h2>
              </div>

              <div
                v-else
                class="input-container -big"
                :class="{ focus: currentlySelectedInput === 'nip' }">
                <label
                  class="text-light"
                  :for="`nip-${_uid}`"
                  >{{ STRINGS.LOGIN_NIP }}</label
                >
                <input
                  :value="nipMask"
                  @input.prevent="updateNip"
                  :id="`nip-${_uid}`"
                  name="nip"
                  type="text"
                  ref="nipInput"
                  :disabled="isUserLogin"
                  @focus="onInputSelect($event, 'nip')"
                  class="-light text-center" />
                <p
                  class="error"
                  v-if="validationErrors.nip">
                  {{ validationErrors.nip }}
                </p>
              </div>
            </form>

            <NumberKeyboard
              :showSubmit="!isUserLogin || (parameters && parameters.bypassPunchButtons)"
              :submitText="submitText"
              :disabled="isUserLogin"
              @submit="onKeyboardSubmit"
              @key="keyPress"
              @erase="erase" />

            <div class="w-full form">
              <div
                class="input-container light"
                v-if="parameters && parameters.bypassPunchButtons">
                <label for="index-note">{{ STRINGS.NOTE }}</label>
                <input
                  id="index-note"
                  type="text"
                  name=""
                  v-model="note"
                  class="" />
                <p
                  class="error"
                  v-if="validationErrors.note">
                  {{ validationErrors.note }}
                </p>
              </div>
            </div>
          </div>

          <BtnEffect
            v-if="isUserLogin && parameters && !parameters.bypassPunchButtons"
            class="w-full"
            @click="logoutUser">
            {{ STRINGS.LOGOUT }}
          </BtnEffect>
        </LoadingContainer>
      </div>
      <div
        class="w-full col-span-2"
        :class="{
          'hidden absolute': parameters && parameters.bypassPunchButtons
        }"
        v-if="isUserLogin">
        <TimerForm
          ref="timerForm"
          :with-scanner="true"
          @submit="punch" />
      </div>
    </div>
  </section>
</template>

<script>
import EventBus, { EVENTS } from "globals/utils/eventbus";
import * as types from "ipad/store/types";
import * as globalTypes from "globals/store/types";
import { isEmpty, tryAsyncFunc } from "globals/utils/utils";
import NumberKeyboard from "globals/components/NumberKeyboard";
import { validateData } from "globals/utils/validation";
import TimerForm from "globals/components/forms/TimerForm";
import PunchStatus from "globals/components/PunchStatus";
import LoadingContainer from "globals/components/LoadingContainer.vue";
import BtnEffect from "globals/components/BtnEffect.vue";
import Time from "globals/components/Time.vue";
import InlineIcon from "globals/components/InlineIcon.vue";
import { v4 } from "uuid";
import moment from "moment";
import { ALWAYS_REFETCH_DATA_AFTER_HOURS } from "globals/utils/constant";

export default {
  name: "Index",
  components: { InlineIcon, Time, BtnEffect, LoadingContainer, PunchStatus, TimerForm, NumberKeyboard },
  data() {
    return {
      isUserLoading: false,

      currentlySelectedInput: "nip",

      nip: "",
      nipMask: "",
      note: "",
      validationErrors: {}
    };
  },
  computed: {
    parameters() {
      return this.$store.state.data.parameters;
    },
    lastPunch() {
      return this.$store.state.data.lastPunch;
    },
    submitText() {
      if (this.parameters && this.parameters.bypassPunchButtons) {
        return this.STRINGS.PAD_BTN_AUTOPUNCH_SUBMIT;
      }

      return this.STRINGS.PAD_BTN_SUBMIT;
    },
    isUserLogin() {
      return this.$store.getters.isLogin;
    },
    hasMessageNotification() {
      return this.$store.state.data.unreadMessages.length > 0;
    }
  },
  created() {
    this.nipMaskTimeoutId = null;

    this.loadInitialCompanyData();
    if (this.$store.getters.isLogin) {
      this.nip = this.$store.getters.userNIP;
      this.updateNipMask();
      //try to get always if he has unread messages
      this.$store.dispatch(types.GET_UNREAD_MESSAGES, this.$store.getters.userUniqueId);
    }
  },
  mounted() {
    //make sure to put focus on input on start
    this.focusOnInput();
  },
  watch: {
    isUserLogin(nextValue, oldValue) {
      //if just connected, but not logout
      if (!nextValue && oldValue) {
        this.nip = "";
        this.nipMask = "";

        //when we change logout, put focus on input
        this.focusOnInput();
      }
    },
    "$store.state.general.isOnline"(isOnline) {
      if (isOnline) {
        this.$store.dispatch(types.GET_ALL_COMPANY_DATA);
      }
    }
  },
  methods: {
    updateNip(e) {
      this.nip = e.target.value;
      this.updateNipMask();
    },
    updateNipMask() {
      const maskPin = this.parameters && this.parameters.maskPin;
      if (!maskPin) {
        this.nipMask = this.nip; //sync
        return;
      }
      clearTimeout(this.nipMaskTimeoutId);

      //need to be at least 1
      if (this.nip.length === 0) {
        this.nipMask = "";
        return;
      }

      let nipArr = this.nip.split("");
      let maskArr = nipArr.map(() => "*");
      maskArr[maskArr.length - 1] = nipArr[nipArr.length - 1]; //show last one
      const maskStr = maskArr.join("");
      const finalMaskStr = nipArr.map(() => "*").join("");

      this.nipMask = maskStr;

      this.nipMaskTimeoutId = setTimeout(() => {
        this.nipMask = finalMaskStr;
      }, 1000);
    },
    focusOnInput() {
      //wait for vue update before putting focus on input
      this.$nextTick(() => {
        if (this.$refs.nipInput) {
          this.$refs.nipInput.focus();
        }
      });
    },
    loadInitialCompanyData() {
      let now = moment();
      let fetchDate = moment(this.$store.state.data.lastCompanyFetchDate);
      let maximumFetchDate = now.add(ALWAYS_REFETCH_DATA_AFTER_HOURS, "hours");
      if (fetchDate.isAfter(maximumFetchDate)) {
        this.$store.dispatch(types.GET_ALL_COMPANY_DATA);
      }
    },
    keyPress(key) {
      if (this[this.currentlySelectedInput] !== "undefined") {
        this[this.currentlySelectedInput] += key;

        if (this.currentlySelectedInput === "nip") {
          this.updateNipMask();
        }
      }
    },
    erase() {
      if (this[this.currentlySelectedInput] !== "undefined") {
        if (this[this.currentlySelectedInput].length > 0) {
          this[this.currentlySelectedInput] = this[this.currentlySelectedInput].slice(
            0,
            this[this.currentlySelectedInput].length - 1
          );
        }
        if (this.currentlySelectedInput === "nip") {
          this.updateNipMask();
        }
      }
    },
    onKeyboardSubmit() {
      if (this.parameters && this.parameters.bypassPunchButtons) {
        this.autoPunch();
      } else {
        this.loginUser();
      }
    },
    async autoPunch() {
      this.validationErrors = {};

      this.validationErrors = validateData({ nip: this.nip }, ["nip"]);

      //must be valid data
      if (!isEmpty(this.validationErrors)) {
        return;
      }

      if (!this.$store.state.general.isOnline) {
        // eslint-disable-next-line no-console
        console.error("Only work online");
        return; //todo
      }

      const [resUser, errUser] = await tryAsyncFunc(this.$store.dispatch)(types.USER_LOGIN, this.nip);
      this.isUserLoading = false;
      if (errUser) {
        //todo
        // eslint-disable-next-line no-console
        console.error(errUser);
        return;
      }

      await this.$nextTick(); //will render timer form so we can call function on it

      let punches = this.$refs.timerForm.getPunches("0"); //0 will make backend auto select punch type
      if (punches.length === 0) {
        return; //todo big error, no punch...
      }

      punches[0].idProjet = null; //make sure we dont send data
      punches[0].idActivite = null; //make sure we dont send data
      punches[0].idOperation = null; //make sure we dont send data
      punches[0].Checked = null; //make sure we dont send data
      punches[0].WorkType = null; //make sure we dont send data

      punches[0].note = this.note; //overwrite note
      await this.punch(punches); //will logout user too

      this.logoutUser();
      this.nip = ""; //reset
      this.nipMask = ""; //reset
      this.note = ""; //reset

      this.focusOnInput();
    },
    async loginUser() {
      this.validationErrors = {};

      this.validationErrors = validateData({ nip: this.nip }, ["nip"]);

      //must be valid data
      if (!isEmpty(this.validationErrors)) {
        return;
      }

      if (!this.$store.state.general.isOnline) {
        //try to get the data from employees
        //fake login so punch work when connected
        this.$store.commit(types.GET_USER_PARAMETERS, {
          CommunicationLanguage: "Francais", //basic
          DefaultActivityId: null,
          DefaultDepartmentId: null, //we dont know
          DefaultOperationId: null,
          DefaultProjectId: null,
          LastPunch: null, //we dont know
          Name: "Hors-Ligne", //we dont know
          Nip: this.nip, //we dont know
          QDeviceCookie: null, //we dont know
          UserId: v4(), //we dont know but we need some id
          employeeUniqueId: null //we dont know
        });
        return;
      }

      this.isUserLoading = true;

      const [resUser, errUser] = await tryAsyncFunc(this.$store.dispatch)(types.USER_LOGIN, this.nip);
      this.isUserLoading = false;
      if (errUser) {
        // eslint-disable-next-line no-console
        console.error(errUser);
        return;
      }

      //try to get always if he has unread messages
      this.$store.dispatch(types.GET_UNREAD_MESSAGES, this.$store.getters.userUniqueId);
    },
    onInputSelect(e, dataName) {
      if (this.$store.state.general.isTouchDevice) {
        if (e && e.target) e.target.blur(); //remove keyboard in ios and android
      }
      this.currentlySelectedInput = dataName;
    },
    logoutUser() {
      this.$store.dispatch(types.USER_LOGOUT);
      this.nip = "";
      this.nipMask = "";

      if (this.$refs.nipInput) {
        this.$refs.nipInput.focus();
      }
    },
    showYouHaveMessagesPopup() {
      EventBus.$emit(EVENTS.SHOW_FLASH, {
        message: this.STRINGS.NEW_MESSAGE,
        color: "yellow",
        textColor: "black"
      });
    },
    async punch(punches) {
      EventBus.$emit(EVENTS.APP_LOADER, true);

      //remove fake ID and add the userNip instead
      if (!this.$store.state.general.isOnline) {
        //Put lat and lng
        punches = punches.map((punchData) => {
          delete punchData.Userid; //delete the fake ID
          punchData.UserNip = this.nip; //add the real user nip
          return punchData;
        });
      }

      if (this.$store.state.general.isOnline) {
        //always refetch location if needed
        const [res, err] = await tryAsyncFunc(this.$store.dispatch)(types.LOAD_LOCATION);
        /*  ignore error
                //err when online is unacceptable
                if (err) {
                  EventBus.$emit(EVENTS.APP_LOADER, false);
                  EventBus.$emit(EVENTS.SHOW_FLASH, {
                    message: this.STRINGS.LOCATION_NEEDED,
                    color: "yellow",
                    textColor: "black"
                  });
                  return;
                }

                //make sure we got lat, only when request are made when online
               if (this.parameters.GeoLocationUsedForced) {
                  if (isEmpty(this.$store.state.data.location.lat)) {
                    EventBus.$emit(EVENTS.SHOW_FLASH, {
                      message: this.STRINGS.LOCATION_NEEDED,
                      color: "yellow",
                      textColor: "black"
                    });
                    EventBus.$emit(EVENTS.APP_LOADER, false);

                    return;
                  }
                } */
      }

      let lastPunch = null;
      //Put lat and lng
      punches.forEach((punchData) => {
        punchData.Latitude = this.$store.state.data.location.lat.toString();
        punchData.Longitude = this.$store.state.data.location.lng.toString();

        //add device token
        punchData.MobileDeviceId = window.__userMobileDeviceId__;

        lastPunch = punchData; //just assign, the last one will be the last one to run
      });

      const [resPunch, errPunch] = await tryAsyncFunc(this.$store.dispatch)(globalTypes.PUNCH, punches);

      EventBus.$emit(EVENTS.APP_LOADER, false);

      let messageArr = [
        `<span class="font-bold text-24">${this.$store.getters.fullName}</span>`,
        `<strong>${this.STRINGS.PUNCH_SAVED}</strong>`
      ];

      if (lastPunch.idProjet) {
        let project = this.$store.state.data.projects.find((p) => {
          return p.ID.toString() === lastPunch.idProjet;
        });
        if (project) {
          messageArr.push(`<strong>${this.STRINGS.PROJECT_TITLE}:</strong> #${project.No} - ${project.Name}`);
        }
      }
      if (lastPunch.idActivite) {
        let activity = this.$store.state.data.activities.find((a) => {
          return a.ID.toString() === lastPunch.idActivite;
        });
        if (activity) {
          messageArr.push(
            `<strong>${this.STRINGS.ACTIVITY_TITLE}:</strong> #${activity.No ? activity.No.trim() : ""} ${
              activity.Name
            }`
          );
        }
      }
      if (lastPunch.idOperation) {
        let operation = this.$store.state.data.operations.find((o) => {
          return o.ID.toString() === lastPunch.idOperation;
        });
        if (operation) {
          messageArr.push(
            `<strong>${this.STRINGS.OPERATION_TITLE}:</strong> #${operation.No ? operation.No.trim() : ""} ${
              operation.Name
            }`
          );
        }
      }

      EventBus.$emit(EVENTS.SHOW_MODAL_FLASH, {
        message: messageArr.join("<br/>"),
        color: "green",
        textColor: "light"
      });

      //auto logout
      this.logoutUser();
    }
  }
};
</script>
